<template>
  <div class="happy-at-sea">
    <h1>Seafarers Happiness Index</h1>
    <Nav />
  </div>
</template>
<script>
	import Nav from '@/components/Nav.vue';
	export default {
		name: 'HappyAtSea',
		components: {
			Nav
		}
	};
</script>
